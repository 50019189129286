import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { userForgetPassword, foregtPasswordValidation } from './actionMethod';
import { useNavigate } from 'react-router-dom';
import infiniteLoader from '../../assets/images/infinite_loader.gif';
import LoginImg from '../../assets/images/login.png';
import '../Login/Login.css'; // Reuse the same CSS file for styling
import SuccessImage from '../../assets/images/success.gif'

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [resetDone,SetResetDone] = useState(false);
  const navigate = useNavigate();

  useEffect(()=>{
    document.body.classList.add('loginReset');
    return()=>{
    document.body.classList.remove('loginReset');
    }
  },[])

  const handleEmailSubmit = (values) => {
    setIsLoading(true);
    let requestBody = { email: values.email };
    setSuccessMessage("");
    setErrorMessage("");
    userForgetPassword(requestBody).then(res => {
      setIsLoading(false);
      if (res.status === 200) {
        setSuccessMessage(`Check your email ${values.email} for verification code!`);
        setTimeout(() => setStep(2), 2000);
      } else {
        handleError(res.message);
      }
    });
  };

  const handleVerificationSubmit = (values) => {
    setIsLoading(true);
    if (values.newPassword !== values.confirmPassword) {
      setErrorMessage('New Password and Confirm Password do not match');
      setIsLoading(false);
      return;
    }
    let requestBody = {
      email,
      password: values.newPassword,
      verification_code: values.verificationCode
    };
    foregtPasswordValidation(requestBody).then(res => {
      setIsLoading(false);
      if (res.status === 200) {
        // setSuccessMessage('Password reset successful. Redirecting to login...');
        SetResetDone(true);
        setErrorMessage(false);
        setSuccessMessage(false);
        setStep(3)
        // setTimeout(() => , 2000);
        setTimeout(() => navigate('/login'), 5000);
      } else {
        handleError(res.message);
        SetResetDone(false);
      }
    });
  };

  const handleError = (message) => {
    if (message === "given email id is not correct") {
      setErrorMessage("No account is associated with the given Email address, Please provide a valid Email !");
      setSuccessMessage("");
    } else if (message === "the verification code given is not correct") {
      
      setErrorMessage("Incorrect/Invalid Verification code");
      setSuccessMessage("");
    } else {
      setErrorMessage(message || "Something went wrong");
      setSuccessMessage("");
    }
  };

  return (
    <div className="loginBG">
      <div className='container'>
        <div className={step===3?"loginFormHide":errorMessage ? 'loginForm error' : 'loginForm'}>
          <div className='loginFormHeader'>
            <img src={step===3?SuccessImage:LoginImg} style={{ maxWidth: step===3?"150px":"250px" }} alt="Login" />
          </div>

          {/* <h2>{step === 1 ? "Forgot Password?" : "Reset Password"}</h2> */}
          <h2>{step === 3 ?"":step === 1 ? "Forgot Password?" : "Reset Password"}</h2>
         {step === 3 ?"": <p style={{ color: "#a8a8a8", marginBottom: 30 }}>
            <small>{step === 1 ? "Provide your account Email ID" : "Enter the verification code sent to your email, and set a new password."}</small>
          </p>}

          <Form
            name="reset-password-form"
            initialValues={{ remember: true }}
            onFinish={step === 1 ? handleEmailSubmit : handleVerificationSubmit}
          >
            {step === 1 && (
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your email!' },
                  { type: 'email', message: 'Please enter a valid email address!' },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            )}

            {step === 2 && (
              <>
                <Form.Item
                  name="verificationCode"
                  rules={[{ required: true, message: 'Please input the verification code!' }]}
                >
                  <Input
                    prefix={<MailOutlined className="site-form-item-icon" />}
                    placeholder="Verification Code"
                    onChange={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  rules={[{ required: true, message: 'Please input your new password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  rules={[{ required: true, message: 'Please confirm your new password!' }]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Form.Item>
              </>
            )}

            {step === 3 && (
                      <>
                        <div className="alert alert-success mt-3">
                          <h2>Password updated successfully</h2>
                      
                        </div>
                      </>
                    )}

            {isLoading ? (
              <img src={infiniteLoader} alt="Loading..." />
            ) : (<>
              {step===3?"":<Form.Item>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  {step === 1 ? "Send Verification Code" : "Reset Password"}
                </Button>
              </Form.Item>}</>
            )}
            {errorMessage && <Alert type="error" message={errorMessage} banner />}
            {successMessage && <Alert type="success" message={successMessage} banner />}
          </Form>

          <p style={{ color: "#000", marginBottom: 30, fontSize: 16 }}>
            Go Back? <span style={{ color: "#394584" }} onClick={() => navigate('/login')}>Login</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
