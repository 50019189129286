const initialState = {
  deviceEui:"",
  appEui: '',
  appPort: "002",
  joinType: '',
  networkSessionKey: '',
  applicationSessionKey: '',
  applicationKey:'',
  rx2DataRate: '',
  rx2Frequency: '',
  spreadingFactor: '',
  txPower: '',
  adrmode:1,
  workMode:"",
  region:"0",
  rxChannel:9,
  reportingInterval:10,
  offsetTemp:"+00",
  offsetHumidity:"+00",
  offsetCo2:"+0000",
  status:false,
  co2:"0000",
  humidity:"00",
  temp:"00",
  battery:"",
  confirmed_mode:0,
  confirmed_mode_retries:7,
  data_storage:0,
  data_retransmission:0,
  model_number:"AQM02",
  firm_ver_number:"1.0.0.0",
  lora_ver_number:"1.0.0.0",
  rssi:"",
  sync_timestamp:"",
  reading_timestamp:"",
  ssid: "",
  password: "",
  pm1Offset: "",
  pm2_5Offset: "",
  pm10Offset: "",
  pressureOffset: "",
  coOffset: "",
  coRo: "",
  no2Offset: "",
  no2Ro: "",
  o3Offset: "",
  tvocOffset: "",
  organizationId: "",
  nh3Offset: "",
  nh3Ro: "",
  noiseOffset: "",
  luxOffset: "",
  occupancyOffset: "",
  pressure: "",
  pm1: "",
  pm2_5: "",
  pm10: "",
  tvoc: "",
  no2: "",
  co: "",
  o3: "",
  nh3: "",
  audioDba: "",
  audioPeakDba: "",
  occupancyStatus: "",
  light: "",
  espFwVersion: "",
  communicationMode: "",
  peopleCount:""
};

const lorwanReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'UPDATE_LORWAN_deviceEui':
      return { ...state, deviceEui: action.payload };
    case 'UPDATE_LORWAN_appEui':
      return { ...state, appEui: action.payload };
    case 'UPDATE_LORWAN_appPort':
      return { ...state, appPort: action.payload };
    case 'UPDATE_LORWAN_joinType':
      return { ...state, joinType: action.payload };
    case 'UPDATE_LORWAN_networkSessionKey':
      return { ...state, networkSessionKey: action.payload };
    case 'UPDATE_LORWAN_applicationSessionKey':
      return { ...state, applicationSessionKey: action.payload };
    case 'UPDATE_LORWAN_applicationKey':
      return { ...state, applicationKey: action.payload };
    case 'UPDATE_LORWAN_deviceAddress':
      return { ...state, deviceAddress: action.payload };
    case 'UPDATE_LORWAN_rx2DataRate':
      return { ...state, rx2DataRate: action.payload };
    case 'UPDATE_LORWAN_rx2Frequency':
      return { ...state, rx2Frequency: action.payload };
    case 'UPDATE_LORWAN_spreadingFactor':
      return { ...state, spreadingFactor: action.payload };
    case 'UPDATE_LORWAN_txPower':
      return { ...state, txPower: action.payload };
    case 'UPDATE_LORWAN_workMode':
      return { ...state, workMode: action.payload };
    case 'UPDATE_LORWAN_region':
      return { ...state, region: action.payload };
    case 'UPDATE_LORWAN_rxChannel':
      return { ...state, rxChannel: action.payload };
    case 'UPDATE_LORWAN_reportingInterval':
      return { ...state, reportingInterval: action.payload };
    case 'UPDATE_LORWAN_offsetTemp':
      return { ...state, offsetTemp: action.payload };
    case 'UPDATE_LORWAN_offsetHumidity':
      return { ...state, offsetHumidity: action.payload };
    case 'UPDATE_LORWAN_offsetCo2':
      return { ...state, offsetCo2: action.payload };
    case 'UPDATE_LORWAN_all':
        return { ...state,...action.payload };
    case 'UPDATE_LORWAN_status':
      return { ...state, status: action.payload };
  case 'UPDATE_LORWAN_co2':
      return { ...state, co2: action.payload };
    case 'UPDATE_LORWAN_humidity':
        return { ...state,humidity:action.payload };
    case 'UPDATE_LORWAN_temp':
        return { ...state,temp:action.payload };        
    case 'UPDATE_LORWAN_deviceType':
        return { ...state,deviceType:action.payload };        
    case 'UPDATE_LORWAN_battery':
        return { ...state,battery:action.payload };            
    case 'UPDATE_LORWAN_adrmode':
        return { ...state,adrmode:action.payload };   
  case 'UPDATE_LORWAN_confirmed_mode':
          return { ...state,confirmed_mode:action.payload };   
    case 'UPDATE_LORWAN_confirmed_mode_retries':
            return { ...state,confirmed_mode_retries:action.payload };   
    case 'UPDATE_LORWAN_data_storage':
            return { ...state,data_storage:action.payload };   
    case 'UPDATE_LORWAN_data_retransmission':
            return { ...state,data_retransmission:action.payload };   
    case 'UPDATE_LORWAN_model_number':
            return { ...state,model_number:action.payload };   
    case 'UPDATE_LORWAN_firm_ver_number':
        return { ...state,firm_ver_number:action.payload };   
    case 'UPDATE_LORWAN_lora_ver_number':
        return { ...state,lora_ver_number:action.payload };   
    case 'UPDATE_LORWAN_rssi':
          return { ...state,rssi:action.payload };   
    case 'UPDATE_LORWAN_sync_timestamp':
        return { ...state,sync_timestamp:action.payload };   
    case 'UPDATE_LORWAN_reading_timestamp':
        return { ...state,reading_timestamp:action.payload };  
    //AQM 05
     // New update actions for additional fields
    case 'UPDATE_LORWAN_ssid':
      return { ...state, ssid: action.payload };
    case 'UPDATE_LORWAN_password':
      return { ...state, password: action.payload };
    case 'UPDATE_LORWAN_pm1Offset':
      return { ...state, pm1Offset: action.payload };
    case 'UPDATE_LORWAN_pm2_5Offset':
      return { ...state, pm2_5Offset: action.payload };
    case 'UPDATE_LORWAN_pm10Offset':
      return { ...state, pm10Offset: action.payload };
    case 'UPDATE_LORWAN_pressureOffset':
      return { ...state, pressureOffset: action.payload };
    case 'UPDATE_LORWAN_coOffset':
      return { ...state, coOffset: action.payload };
    case 'UPDATE_LORWAN_coRo':
      return { ...state, coRo: action.payload };
    case 'UPDATE_LORWAN_no2Offset':
      return { ...state, no2Offset: action.payload };
    case 'UPDATE_LORWAN_no2Ro':
      return { ...state, no2Ro: action.payload };
    case 'UPDATE_LORWAN_o3Offset':
      return { ...state, o3Offset: action.payload };
    case 'UPDATE_LORWAN_tvocOffset':
      return { ...state, tvocOffset: action.payload };
    case 'UPDATE_LORWAN_organizationId':
      return { ...state, organizationId: action.payload };
    case 'UPDATE_LORWAN_nh3Offset':
      return { ...state, nh3Offset: action.payload };
    case 'UPDATE_LORWAN_nh3Ro':
      return { ...state, nh3Ro: action.payload };
    case 'UPDATE_LORWAN_noiseOffset':
      return { ...state, noiseOffset: action.payload };
    case 'UPDATE_LORWAN_luxOffset':
      return { ...state, luxOffset: action.payload };
    case 'UPDATE_LORWAN_occupancyOffset':
      return { ...state, occupancyOffset: action.payload };
    case 'UPDATE_LORWAN_pressure':
      return { ...state, pressure: action.payload };
    case 'UPDATE_LORWAN_pm1':
      return { ...state, pm1: action.payload };
    case 'UPDATE_LORWAN_pm2_5':
      return { ...state, pm2_5: action.payload };
    case 'UPDATE_LORWAN_pm10':
      return { ...state, pm10: action.payload };
    case 'UPDATE_LORWAN_tvoc':
      return { ...state, tvoc: action.payload };
    case 'UPDATE_LORWAN_no2':
      return { ...state, no2: action.payload };
    case 'UPDATE_LORWAN_co':
      return { ...state, co: action.payload };
    case 'UPDATE_LORWAN_o3':
      return { ...state, o3: action.payload };
    case 'UPDATE_LORWAN_nh3':
      return { ...state, nh3: action.payload };
    case 'UPDATE_LORWAN_audioDba':
      return { ...state, audioDba: action.payload };
    case 'UPDATE_LORWAN_audioPeakDba':
      return { ...state, audioPeakDba: action.payload };
    case 'UPDATE_LORWAN_occupancyStatus':
      return { ...state, occupancyStatus: action.payload };
    case 'UPDATE_LORWAN_light':
      return { ...state, light: action.payload };
    case 'UPDATE_LORWAN_espFwVersion':
      return { ...state, espFwVersion: action.payload };
    case 'UPDATE_LORWAN_communicationMode':
      return { ...state, communicationMode: action.payload };
    case 'UPDATE_LORWAN_peopleCount':
      return { ...state, peopleCount: action.payload };  
           
    default:
      return state;
  }
};

export default lorwanReducer;
