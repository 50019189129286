import React, { useContext } from 'react';
import './Scanner.css'
import Spinner from '../../spinner.gif';
import { ActionsContext } from '../../contexts/context';
import NFCScan from '../../assets/images/scan.gif'

const ScannerLoader = () => {
  const { actions, setActions} = useContext(ActionsContext);
    return (
      <div className="scanner">

        <div className="scanner-container">
          <h2 className="scanner-text" style={{fontWeight:300}}>Scanning for Read...</h2>
          <img src={NFCScan} alt="spinning log" className="scanner-image"/>
        </div>
        <div className='scannerFooter' onClick={()=>setActions({...actions, scan: null})}>Stop Scanning</div>
      </div>
    );
};

export default ScannerLoader;