// GeneralTab.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Form, Row, Col,Select,Button } from 'antd';

const GeneralTab = (props) => {
  const {Option} = Select
  const generalState = useSelector((state) => state.lorwan);
  const dispatch = useDispatch();
  const min= generateArray()
function generateArray() {
  let arr = [];
  for (let i = 1; i <= 1440; i++) {
    arr.push(i.toString().padStart(4, '0'));
  }
  return arr;
}
  // const handleFieldChange = (field, value) => {
  //   dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  // };
const handleFieldChange = (field, value, type, length) => {
  console.log(field, value, type, length)
  if (field === "region") {
    dispatch({ type: `UPDATE_LORWAN_rx2DataRate`, payload: "" });
    dispatch({ type: `UPDATE_LORWAN_rxChannel`, payload: "" });
  }

  let hex = new RegExp(`^[0-9A-Fa-f]{1,${length}}$`); // Dynamic length for hex
  let no = new RegExp(`^\\d{1,${length}}$`);  // Dynamic length for numbers
  let noString = new RegExp(`^-?\\d{1,${length}}$`);  // Dynamic length for noString
  
  if ((type === "hex" && hex.test(value) && value.length <= length) || (value.length==0) ) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "number" && no.test(value) && value.toString().length <= length )|| (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  } else if ((type === "noString" && noString.test(value) && value.length <= length) || (value.length==0)) {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  }
  
};


  return (
    <Form>
      <Row gutter={16} className=''>

        <Col span={24}>

          <Form.Item label="Reporting Interval" className='settingfield'>
            <Select
              value={generalState.reportingInterval}
              onChange={(e) => handleFieldChange('reportingInterval', e,'number',4)}
              status={props.error && props.error['reportingInterval']?"error":""}
              disabled={props.mode && props.mode=="disabled"?true:false}
              showSearch
            >
              {min.map(el=>{
               return <Option value={el} key={"minutes"+el}>{parseInt(el)+" min"}</Option>
              })}
            </Select>

          </Form.Item>
        </Col>
          
      </Row>


    </Form>
  );
};

export default GeneralTab;
