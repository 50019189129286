import axios from "axios";

let token = 'tituw3958589'

let prefixURL = process.env.REACT_APP_URL_LOGIN

export function createTemplate(requestBody) {
    requestBody.token = token
    return axios.post(prefixURL + `/nfc/create_template` , requestBody).then(res=>res.data).catch(err=>err)
}

export function getTemplate(session,org_id) {
    let requestBody = {}
    requestBody.token = token
    requestBody.session = '123456789'
    return axios.get(prefixURL + `/nfc/get_template?session=${session}&org_id=${org_id}`).then(res=>res.data).catch(err=>err)
}
export function updateDeviceOffset(requestBody){
    return axios.post(prefixURL+`/device/update_device_offset`,requestBody).then(res=>res.data).catch(err=>err);
}
