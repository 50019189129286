import React,{useState} from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Collapse, Input, Row } from 'antd';


import { ActionsContext } from '../../contexts/context';
import WriteLanding from  '../Scanner/Writer/index'
import ScannerLoader from "../Scanner/ScannerLoader";

const { Panel } = Collapse;

function Demo() {
  
  const dispatch = useDispatch();
  const [actions, setActions] = useState(null);
  const {scan, write} = actions || {};
  const [appEui,SetAppEUI]= useState("");

  const [serialNumber,setSerialNumber]= useState('');
  const [message,setMessage]= useState('');  
  const [messageAr,SetMessageAr] = useState({});
  
  const actionsValue = {actions,setActions};
  

   const onHandleAction = (actions) =>{
    setActions({...actions});
  }



  function writeSucess(){
       onHandleAction({scan: null, write: null})       
  }

function ReadHandlerData(){
    onHandleAction({scan: 'scanning', write: null})
    scanNfcData();

  }

  function WriteHandlerrData(){
        onHandleAction({scan: null, write: 'scanning'})
        writeNfcData()
  }



 function stringToHexBytes(hexString){
    const hexValues = [];
    for (let i = 0; i < hexString.length; i += 2) {
        const hexPair = hexString.substr(i, 2);
        const decimalValue = parseInt(hexPair, 16);
        hexValues.push(decimalValue);
    }
    return hexValues;
}


  


  
    

    const onReading = ({message, serialNumber}) => {
        setSerialNumber(serialNumber);
        for (const record of message.records) {
            const textDecoder = new TextDecoder(record.encoding);
            switch (record.recordType) {
                case "text":
                    SetMessageAr(record)
                    
                    // alert("textDecoder",JSON.stringify(textDecoder.decode(record.data)));
                    setMessage(stringToHexBytes(textDecoder.decode(record.data)));
                
                    SetAppEUI(textDecoder.decode(record.data));
                    

                    onHandleAction({scan: null, write: null})
                    break;
                case "url":
                    // TODO: Read URL record with record data.
                    break;
                default:
                    SetMessageAr(record)
                    // alert("textDecoder",JSON.stringify(textDecoder.decode(record.data)));

                    setMessage(stringToHexBytes(textDecoder.decode(record.data)));
                
                    SetAppEUI(textDecoder.decode(record.data));
                    
                    onHandleAction({scan: null, write: null})
                }
        }
    };
    const scanNfcData= async()=>{
        if ('NDEFReader' in window) { 
            try {
                const ndef = new window.NDEFReader();
                console.log("ndef",ndef);
                await ndef.scan();
                
                console.log("Scan started successfully.");
                ndef.onreadingerror = () => {
                    console.log("Cannot read data from the NFC tag. Try another one?");
                };
                
                ndef.onreading = event => {
                    console.log("NDEF message read.");
                    console.log("event",event);
                    onReading(event);

                    setActions({
                        scan: 'scanned',
                        write: null
                    });
                    //ndef.stop();
                };

            } catch(error){
                console.log(`Error! Scan failed to start: ${error}.`);
            };
}
    }


  const writeNfcData = async() => {
    // Convert the current state value to hexadecimal bytes
    const hexBytes = stringToHexBytes(appEui);
    console.log("hexByte",hexBytes);
    
if ('NDEFReader' in window) {
    try {
      const ndef = new window.NDEFReader();
     
      if ('NDEFReader' in window) {
        
            // This line will avoid showing the native NFC UI reader
            await ndef.scan();
            // await ndef.write({records: [{ recordType: "text", data: hexBytes}]});
            await ndef.write(hexBytes);

        
           // ndef.stop();

      } else {
       
      }
    } catch (error) {
      console.error("Error initializing NDEFReader:", error);
    }
  } else {
   
  }

  };


  return (
    <div className="App">
      <div className="container">
       <h1>Demo Component</h1>
                <Input type="text" className="form-control" value={appEui} onChange={(e)=>SetAppEUI(e.target.value)} style={{marginBottom:20,padding:15}} placeholder="APP EUI"/>

            <button onClick={()=>ReadHandlerData() } className="btn  btn-transparent">Scan Now</button>
            <button onClick={()=>WriteHandlerrData()} className="btn" style={{marginTop:"10px"}}>Write Now</button>

        <ActionsContext.Provider value={actionsValue}>
          {write && <WriteLanding message={""} writeSucess={writeSucess}/>}
           {scan && <ScannerLoader/>} 
        </ActionsContext.Provider>


        <div>
           <p>Serial: {serialNumber}</p> 
           <p>APP EUI: {message}</p> 
           <p>{JSON.stringify(messageAr,null,2)}</p>
        </div>
    </div>
   </div> 
  );
}

export default Demo;
