// ThresholdTab.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Switch, Form, Row, Col } from 'antd';

const ThresholdTab = () => {
  const thresholdState = useSelector((state) => state.threshold);
  const dispatch = useDispatch();

  const handleFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_THRESHOLD_${field}`, payload: value });
  };

  return (
    <Form>
      <Row gutter={16} className='br-btm-space-issue'>
        <Col span={18}>
            <label>PAR Switch</label>
        </Col>
        <Col span={6}>
            <Switch
              checked={thresholdState.parSwitch}
              onChange={(checked) => handleFieldChange('parSwitch', checked)}
            />
        </Col>
     </Row>  
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item label="Over" className='settingfield' >
            <Input
              value={thresholdState.over}
              onChange={(e) => handleFieldChange('over', e.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Before" className='settingfield'>
            <Input
              value={thresholdState.before}
              onChange={(e) => handleFieldChange('before', e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default ThresholdTab;
