import axios from "axios";

let token = 'tituw3958589'

let prefixURL = process.env.REACT_APP_URL_LOGIN

export function userForgetPassword(requestBody) {
    return axios.post(prefixURL + `/user/forgot_password` , requestBody).then(res=>res.data).catch(err=>err)
}

export function foregtPasswordValidation(requestBody) {

    return axios.post(prefixURL + `/user/forgot_password_validation` , requestBody).then(res=>res.data).catch(err=>err)
}