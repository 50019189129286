import React,{useState} from 'react';
import '../assets/css/TopHeader.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Logo from '../assets/images/safe_text_logo.png'
import { LogoutOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { logoutUser } from '../components/Login/actionMethod';
import { useSelector, useDispatch } from 'react-redux';
import { ActionsContext } from '../contexts/context';
const TopHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const generalState = useSelector((state) => state.lorwan);
  const [actions, setActions] = useState(null);
  const actionsValue = { actions, setActions };
  const [isOpen, setIsOpen] = useState(false);
  const [logoutModal,SetLogoutModal] = useState(false);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const onHandleAction = (actions) => {
    setActions({ ...actions });
    // Send message to React Native WebView for scanning
    if (actions.scan) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'startScan' }));
    }
  };

  const resetSate = ()=>{
    console.log("reset state Triggered");
    dispatch({ type: 'UPDATE_LORWAN_deviceEui', payload: "" });
    dispatch({ type: `UPDATE_SCAN_deviceEui`, payload: "" });
    dispatch({ type: `UPDATE_RESET_deviceEui`, payload: "" });
    localStorage.removeItem('nfcdeviceEui');
    dispatch({ type: 'UPDATE_SCAN_status', payload: false });
    dispatch({ type: 'UPDATE_LORWAN_status', payload: false });
    setActions({
      scan: null,
      write: null
    });
  }
  function handleLogout(e){
    e.preventDefault();
    SetLogoutModal(false);
     localStorage.removeItem('userLoggedinSafeAirConfig')
     localStorage.removeItem('isLoggedinSafeAirConfig')
     setIsOpen(false);
     navigate('/login');
  }
  return (
    <header className="top-header">
        <div className='container'>
      <div className="logoArea">
      <div className="menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>  
            <div className="logo">
         <img src={Logo} alt="Logo" />
        </div>
      </div>
    <nav className="menu">
{/* <div className='logoutIcon' onClick={()=>SetLogoutModal(true)}>
           <LogoutOutlined style={{ fontSize: '16px', color: '#fff' }} />
      </div> */}
     <div className={`hamburger-menu close ${isOpen ? 'open' : ''}`} onClick={()=>setIsOpen(false)}></div> 
     <div className={`hamburger-menu ${isOpen ? 'open' : ''}`}>
     <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
       
      <ul>
        <li><NavLink to="/" onClick={()=>{toggleMenu();resetSate()}}>Status</NavLink></li>
        <li>{generalState.deviceEui && generalState.deviceEui!=""?<NavLink to={generalState.model_number && generalState.model_number=="AQM05"?"/setting-aqm05":'setting' } onClick={toggleMenu}>Settings</NavLink>:<a className="disbaled" href="/setting" aria-current="page" onClick={(e)=>e.preventDefault()} aria-disabled>Settings</a>}</li>
        <li>{generalState.deviceEui && generalState.deviceEui!=""?<NavLink to={generalState.model_number && generalState.model_number=="AQM05"? "reset-aqm05":"/reset"}  onClick={toggleMenu}>Reset</NavLink>:<a className="disbaled" href="/reset" aria-current="page" onClick={(e)=>e.preventDefault()} aria-disabled>Reset</a>}</li>
        <li><a className="" href="/" aria-current="page" onClick={(e)=>handleLogout(e)}>Logout</a></li>
        {/* <li><NavLink to="/template"  onClick={toggleMenu}>Template</NavLink></li> */}
        {/* <li><NavLink to="/demo"  onClick={toggleMenu}>demo</NavLink></li> */}
        
      </ul>
    </div>
      </nav>
      </div>
       <Modal
      title="Logout Confirmation"
      open={logoutModal}
      onOk={handleLogout}
      onCancel={()=>SetLogoutModal(false)}
      okText="Logout"
      cancelText="Cancel"
    >
      <p>Are you sure you want to logout?</p>
    </Modal>
    </header>
  );
};

export default TopHeader;
