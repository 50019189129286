import React, { useContext } from 'react';
import '../Scanner.css'

import { ActionsContext } from '../../../contexts/context';
import NFCScan from '../../../assets/images/scan.gif'

const WriteLoader = ({title}) => {
  console.log("title",title)
  const { actions, setActions} = useContext(ActionsContext);
  
    return (
      <div className="scanner">

        <div className="scanner-container">
          <h2 className="scanner-text" style={{fontWeight:300}}>{title?title:"Scanning for Write..."}</h2>
          <img src={NFCScan} alt="spinning log" className="scanner-image"/>
        </div>
        <div className='scannerFooter' onClick={()=>setActions({...actions, scan: null,write:null})}>Stop Scanning</div>
      </div>
    );
};

export default WriteLoader;