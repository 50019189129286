import React,{useState,useContext} from 'react';
import WriteLoader from './WriteLoader';
import { ActionsContext } from '../../../contexts/context';

const WriteLanding = ({message,title}) => {
    console.log("Write Landing",message)
    // const [actions, setActions] = useState(null);
         const { actions, setActions} = useContext(ActionsContext);
    const {scan, write} = actions || {};
    const actionsValue = {actions,setActions};

     const onHandleAction = (actions) =>{
        setActions({...actions});
    }
    // onHandleAction({scan: null, write: 'writing'})
    const onWrite = async(message) => {
        if ('NDEFReader' in window) { 
        try {
            
            // This line will avoid showing the native NFC UI reader
            const ndef = new window.NDEFReader();
// await ndef.write(
//   "Hello World demo"
// ).then(() => {
//   console.log("Message written.");
// }).catch(error => {
//   console.log(`Write failed :-( try again: ${error}.`);
// });

            
            await ndef.write({records: [{ recordType: "text", data: message }]});
            
            onHandleAction({scan: null, write: null})

        } catch (error) {
            console.log(error);
        }
        }
    }
    // onWrite(message)

    return (
      <WriteLoader title={title} />
    );
};

export default WriteLanding;