// CalibrationSetting.js
import React from 'react';
import {   Row } from 'antd';
import OffsetField from './components/OffsetField';

const CalibrationSetting = (props) => {
  
  return (      
         <Row gutter={16}>
      <OffsetField
        label="CO RO Calibration"
        field="coRo"
        resolution={1000}
        range={[0, 9999999]}
        charLength={7}
        format={'9999999'}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="NO2 RO Calibration"
        field="no2Ro"
        resolution={100}
        range={[0,9999999]}
        format={'9999999'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />
      <OffsetField
        label="NH3 RO Calibration"
        field="nh3Ro"
        resolution={1000}
        range={[0, 9999999]}
        format={'9999999'}
        charLength={7}
        mode={props.mode && props.mode=="disabled"?true:false}
        error={props.error}
      />

      </Row>


  );
};

export default CalibrationSetting;
