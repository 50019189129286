import React from 'react';
import { Routes, Route, useNavigate,Navigate } from 'react-router-dom';
import Settings from '../components/Settings/Settings';
import SettingsAQM05 from '../components/SettingsAQM05/Settings';
import Login from '../components/Login/Login';
import Reset from '../components/Reset/Reset';
import ResetAQM05 from '../components/ResetAQM05/Reset';
import Template from '../components/Template/Template';
import ScanLanding from '../components/Scanner/index';
import ResetPassword from '../components/ResetPassword/ResetPassword';
import Demo from '../components/demo';
import { useSelector, useDispatch } from 'react-redux';
// import TopHeader from './TopHeader';


const Router = () => {
  const isLoggedIn = localStorage.getItem('isLoggedinSafeAirConfig') ? true : false;
  const dispatch = useDispatch();
  const generalState = useSelector((state) => state.lorwan);
  const isScanned = generalState.deviceEui && generalState.deviceEui!=""?true:false
  // const isScanned = true
  console.log("generalState.deviceEui",generalState.deviceEui);
  const navigate = useNavigate();
  return (
    <Routes>
      <Route path="/login" element={<Login />}  />
      <Route path="/setting" element={isLoggedIn?isScanned || !isScanned?<Settings />:<Navigate to="/scan"/>: <Navigate to="/login"/>} isLoggedIn={isLoggedIn} />
      <Route path="/setting-aqm05" element={isLoggedIn?isScanned || !isScanned?<SettingsAQM05 />:<Navigate to="/scan"/>: <Navigate to="/login"/>} isLoggedIn={isLoggedIn} />
      <Route path="/reset-aqm05" element={isLoggedIn?isScanned || !isScanned?<ResetAQM05 />:<Navigate to="/scan"/>: <Navigate to="/login"/>} isLoggedIn={isLoggedIn} />
      <Route path="/reset" element={isLoggedIn?isScanned || !isScanned?<Reset />:<Navigate to="/scan"/>: <Navigate to="/login"/>} isLoggedIn={isLoggedIn}  />
      <Route path="/reset-password" element={<ResetPassword />} isLoggedIn={isLoggedIn}  />
      <Route path="/template" element={isLoggedIn?<Template />: <Navigate to="/login"/>} isLoggedIn={isLoggedIn}  />
      <Route path="/scan" element={isLoggedIn?<ScanLanding />: <Navigate to="/login"/>} isLoggedIn={isLoggedIn}  />
      <Route path="/demo" element={isLoggedIn?<Demo />: <Navigate to="/login"/>} isLoggedIn={isLoggedIn}  />
      <Route path="/" element={isLoggedIn?<ScanLanding />: <Navigate to="/login"/>}  isLoggedIn={isLoggedIn} />
    </Routes>
  );
};

export default Router;