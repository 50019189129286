import { Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Button, Collapse } from 'antd';

function Template() {
  const savedTemplate = localStorage.getItem('nfcTemplate')?JSON.parse(localStorage.getItem('nfcTemplate')):[]
  const navigate = useNavigate();
  const { Panel } = Collapse;
const offsets = {
  appEui: 16,
  appPort: 3,
  joinType: 1,
  networkSessionKey: 32,
  applicationSessionKey: 32,
  workMode: 1,
  region: 1,
  rx2DataRate: 2,
  rxChannel: 1,
  spreadingFactor: 2,
  txPower: 1,
  reportingInterval: 2,
  offsetTemp: 3,
  offsetHumidity: 3,
  offsetCo2: 5
};
  let sequnece =[
      {id:1,key:"deviceEui",required:false,validation:"hex",length:16},
      {id:2,key:"deviceType",required:true,validation:"number",length:2},
      {id:3,key:"applicationKey",required:true,validation:"hex",length:32},
      {id:4,key:"appPort",required:true,validation:"number",length:3},
      {id:5,key:"joinType",required:true,validation:"number",length:1},
      {id:6,key:"networkSessionKey",required:true,validation:"hex",length:32},
      {id:7,key:"applicationSessionKey",required:true,validation:"hex",length:32},
      {id:8,key:"workMode",required:true,validation:"number",length:1},
      {id:9,key:"region",required:true,validation:"number",length:1},
      {id:10,key:"rx2DataRate",required:true,validation:"number",length:2},
      // {id:11,key:"rxChannel",required:true,validation:"number",length:1},
      {id:12,key:"spreadingFactor",required:true,validation:"number",length:2},
      {id:13,key:"txPower",required:true,validation:"number",length:2},
      {id:14,key:"reportingInterval",required:true,validation:"number",length:2},
      {id:15,key:"offsetTemp",required:true,validation:"numberString",length:3},
      {id:16,key:"offsetHumidity",required:true,validation:"numberString",length:3},
      {id:17,key:"offsetCo2",required:true,validation:"numberString",length:5}
  ]

  const [listTemplate,SetListTemplate] = useState([
    {id:1,name:"Template 1",type:"default",value:"2:1234567890ABCDEF,3:1234567890ABCDEF1234567890ABCDEF,4:002,5:1,8:0,9:0,10:00,11:1,12:01,13:3,14:,15:+01,16:+00,17:+0000"},
    ...savedTemplate
  ]);

  const generalState = useSelector((state) => state.lorwan);
  const dispatch = useDispatch();

  const handleFieldChange = (field, value) => {
    dispatch({ type: `UPDATE_LORWAN_${field}`, payload: value });
  };

  // Define offsets

  const [templateSelected,setTemplateSelected]= useState(null);

  function selectTemplate(id,value){
    setTemplateSelected(id);    
    readWriteDataHandler(value);
    
    setTimeout(function(){
      navigate('/setting');
    })
  }

  // Function to extract values based on offsets


      function readWriteDataHandler(inputString){
        console.log("inputString",inputString)
        let arr=inputString.split(",");
        let allField={}
        console.log("arr",arr)
        for(let i=0; i<arr.length;i++){
            let val = arr[i].split(":");
            let key = sequnece.find(el=>el.id == val[0]);
            if(key){
                console.log("key.key",key.key,val)               
                allField[key.key] = val[1];
            }
             
        }
        dispatch({ type: `UPDATE_LORWAN_all`, payload: allField });
        setTimeout(function(){
                navigate('/setting');
        },100)
  }

  function  displayTemplateData(inputString){
    let html=[];
    const extractValue = (offset, length) => {
    return inputString.substr(offset, length);
  };
// Extract values

    const appEui = extractValue(0, offsets.appEui);
    const appPort = extractValue(offsets.appEui, offsets.appPort);
    const joinType = extractValue(offsets.appEui + offsets.appPort, offsets.joinType);
    const networkSessionKey = extractValue(offsets.appEui + offsets.appPort + offsets.joinType, offsets.networkSessionKey);
    const applicationSessionKey = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey, offsets.applicationSessionKey);
    const workMode = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey, offsets.workMode);
    const region = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode, offsets.region);
    const rx2DataRate = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region, offsets.rx2DataRate);
    const rxChannel = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate, offsets.rxChannel);
    const spreadingFactor = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel, offsets.spreadingFactor);
    const txPower = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel + offsets.spreadingFactor, offsets.txPower);
    const reportingInterval = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel + offsets.spreadingFactor + offsets.txPower, offsets.reportingInterval);
    const offsetTemp = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel + offsets.spreadingFactor + offsets.txPower + offsets.reportingInterval, offsets.offsetTemp);
    const offsetHumidity = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel + offsets.spreadingFactor + offsets.txPower + offsets.reportingInterval + offsets.offsetTemp, offsets.offsetHumidity);
    const offsetCo2 = extractValue(offsets.appEui + offsets.appPort + offsets.joinType + offsets.networkSessionKey + offsets.applicationSessionKey + offsets.workMode + offsets.region + offsets.rx2DataRate + offsets.rxChannel + offsets.spreadingFactor + offsets.txPower + offsets.reportingInterval + offsets.offsetTemp + offsets.offsetHumidity, offsets.offsetCo2);
    html.push(<Row gutter={16} key="appEui">
            <Col  span={12}>App EUI</Col>
            <Col  span={12}><strong>{appEui.toUpperCase()}</strong></Col>
          </Row>)
    html.push(<Row gutter={16} key="appPort">
            <Col as={"span"} span={12}>App Port</Col>
            <Col as={"span"} span={12}><strong>{appPort.toUpperCase()}</strong></Col>
          </Row> )    
    html.push(<Row gutter={16} key="joinType">
      <Col as={"span"} span={12}>Join Type</Col>
      <Col as={"span"} span={12}><strong>{joinType.toUpperCase()}</strong></Col>
</Row> )     
html.push(<Row gutter={16} key="networkSessionKey">
      <Col as={"span"} span={12}>Network Session Key</Col>
      <Col as={"span"} span={12}><strong>{networkSessionKey.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="applicationSessionKey">
      <Col as={"span"} span={12}>Application Session Key</Col>
      <Col as={"span"} span={12}><strong>{applicationSessionKey.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="workMode">
      <Col as={"span"} span={12}>Work Mode</Col>
      <Col as={"span"} span={12}><strong>{workMode.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="region">
      <Col as={"span"} span={12}>Region</Col>
      <Col as={"span"} span={12}><strong>{region.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="rx2DataRate">
      <Col as={"span"} span={12}>Rx2 DataRate</Col>
      <Col as={"span"} span={12}><strong>{rx2DataRate.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="rxChannel">
      <Col as={"span"} span={12}>Rx Channel</Col>
      <Col as={"span"} span={12}><strong>{rxChannel.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="spreadingFactor">
      <Col as={"span"} span={12}>Spreading Factor</Col>
      <Col as={"span"} span={12}><strong>{spreadingFactor.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="txPower">
      <Col as={"span"} span={12}>Tx Power</Col>
      <Col as={"span"} span={12}><strong>{txPower.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="reportingInterval">
      <Col as={"span"} span={12}>Reporting Interval</Col>
      <Col as={"span"} span={12}><strong>{reportingInterval.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="offsetTemp">
      <Col as={"span"} span={12}>offset Temperature</Col>
      <Col as={"span"} span={12}><strong>{offsetTemp.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="offsetHumidity">
      <Col as={"span"} span={12}>Offset Humidity</Col>
      <Col as={"span"} span={12}><strong>{offsetHumidity.toUpperCase()}</strong></Col>
</Row>)
html.push(<Row gutter={16} key="offsetCo2">
      <Col as={"span"} span={12}>Offset Co2</Col>
      <Col as={"span"} span={12}><strong>{offsetCo2.toUpperCase()}</strong></Col>
</Row>)

return html;

        }


  return (
      <div className="App">
        <div className="container">
          <div className="templateRows">
        <h4 style={{fontWeight:"normal"}}>All Template</h4>

        <Collapse accordion>
      
      {/* Add more panels as needed */}
    
        {listTemplate.map((el,index)=>{
          console.log(el.value)
          return(
      <Panel  
        header={
        <Row gutter={16} className={templateSelected === el.id?"templateList templateListActive":"templateList"} key={"template"+index} >
           <Col as={"span"} span={12}>{el.name}</Col> 
             <Col as={"span"} span={12}><Button onClick={()=>selectTemplate(el.id,el.value)}>{templateSelected === el.id?"Selected":"Select"}</Button>
            </Col>
            {/* <Col as={"span"}>{el.value}</Col> */}
          </Row>} key={"AccordionFIeld"+index}>
            <div className="PastData">
              {el.value?displayTemplateData(el.value):""}
          
          </div>
      </Panel>)
      

        })}
        </Collapse>
        </div>
        </div>
    </div>
  );
}

export default Template;
